import { sendAxiosRequest } from "@api/request.js";

// promotions
async function getPromotions() {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/promociones`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
    },
  };

  return await sendAxiosRequest(config);
}

export {
  // promotions
  getPromotions,
};
