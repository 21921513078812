import { storeToRefs } from "pinia";
import { brandsStore } from "@stores/brands_store.js";

export const useBrandsStore = () => {
  const myBrandsStore = brandsStore();

  const {
    status,
    loadingBrands,
    message,
    brands,
    brandsSearchResult,
    phones,
    emails,
  } = storeToRefs(myBrandsStore);

  const getBrands = async () => {
    await myBrandsStore.getBrands();
  };

  const reload = () => {
    myBrandsStore.reload();
  };

  const filter = async (criteria) => {
    if (criteria) {
      var needle = criteria.toLowerCase();
      brandsSearchResult.value = brands.value.filter(
        (item) =>
          item.NAME.toLowerCase().includes(needle) ||
          item.DESCRIPTION.toLowerCase().includes(needle)
      );
    } else {
      brandsSearchResult.value = brands.value.filter((item) => item);
    }
  };

  return {
    ///* properties
    status,
    loadingBrands,
    message,
    brands,
    brandsSearchResult,
    phones,
    emails,

    ///* methods
    getBrands,
    reload,
    filter,
  };
};
