<script setup>
import { onMounted, ref } from "vue";
import Network from "@components/modals/Network.vue";
import { useBrandsStore } from "@composables/use_brands_store";

const { brands, phones, emails } = useBrandsStore();

onMounted(() => {
  console.log("guest footer mounted");
});
</script>

<template>
  <!-- Guest footer -->
  <footer class="py-3 px-3" style="background-color: #333; color: white">
    <div
      class="row w-100 justify-content-between"
      style="background-color: #333; color: white"
    >
      <div class="col-12 col-md-4">
        <div class="widget mb-2">
          <div class="widget-title mb-1 pb-1">Servicios al cliente</div>
          <ul class="links">
            <li>
              <router-link :to="{ name: 'AboutUs' }" class="text-white"
                >Quienes somos</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'FrequentQuestions' }"
                class="text-white"
                >Preguntas frecuentes</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'TermsAndConditions' }"
                class="text-white"
                >Términos y condiciones</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Shipments' }" class="text-white"
                >Envíos</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Branches' }" class="text-white"
                >Sucursales</router-link
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div class="widget mb-2">
          <div class="widget-title mb-1 pb-1">Información de contacto</div>
          <ul class="contact-info px-0">
            <li v-if="phones.length">
              <span class="contact-info-label">Telefonos:</span>
              <ul class="contact-info">
                <li v-for="(phone, index) in phones" :key="`phone_${index}`">
                  <a class="link text-white" :href="`tel:${phone.phone}`">
                    {{ phone.name }}: {{ phone.phone }}
                  </a>
                </li>
              </ul>
            </li>
            <li v-if="emails.length" class="mt-2">
              <span class="contact-info-label">Emails:</span>
              <ul class="contact-info">
                <li v-for="(email, index) in emails" :key="`email_${index}`">
                  <a
                    class="link text-white"
                    target="_blank"
                    :href="`mailto:${email.email}`"
                  >
                    {{ email.name }}: {{ email.email }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div class="widget mb-2">
          <div class="widget-title mb-1 pb-1">Encuéntranos en:</div>
          <div class="row social-icons">
            <Network :type="'facebook'" :brands="brands" :up="true" />
            <Network :type="'instagram'" :brands="brands" :up="true" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.widget-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 736px) {
  .widget-title {
    font-size: 1rem;
  }
  .contact-info,
  .links {
    font-size: 14px !important;
  }
}
</style>
