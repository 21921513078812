import { sendAxiosRequest } from "@api/request.js";

// obtener datos para el registro
async function getTextBanners() {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/getTextBanners`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
    },
  };

  return await sendAxiosRequest(config);
}

export { getTextBanners };
