<script setup>
import close from "/src/assets/img/icons/close.svg";
import down from "/src/assets/img/icons/down.svg";
import home from "/src/assets/img/icons/home.svg";
import left from "/src/assets/img/icons/left.svg";
import menu from "/src/assets/img/icons/menu.svg";
import right from "/src/assets/img/icons/right.svg";
import search from "/src/assets/img/icons/search.svg";
import shopping_cart from "/src/assets/img/icons/shopping_cart.svg";
import cart_check from "/src/assets/img/icons/cart_check.svg";
import trash from "/src/assets/img/icons/trash.svg";
import up from "/src/assets/img/icons/up.svg";
import user from "/src/assets/img/icons/user.svg";
import password_lock from "/src/assets/img/icons/password_lock.svg";
import password from "/src/assets/img/icons/password.svg";
import logout from "/src/assets/img/icons/logout.svg";
import cancelar from "/src/assets/img/icons/cancelar.svg";
import info from "/src/assets/img/icons/info.svg";
import compras from "/src/assets/img/icons/compras.svg";

const props = defineProps({
  name: String,
  text: String,
  width: Number,
  alt: String,
  classes: String,
});

const emit = defineEmits(["ontap"]);

function onTap() {
  console.log("icon ontap");
  emit("ontap");
}
function getIcon(name) {
  switch (name) {
    case "close":
      return close;
    case "cancelar":
      return cancelar;
    case "down":
      return down;
    case "home":
      return home;
    case "left":
      return left;
    case "menu":
      return menu;
    case "right":
      return right;
    case "search":
      return search;
    case "shopping_cart":
      return shopping_cart;
    case "cart_check":
      return cart_check;
    case "trash":
      return trash;
    case "user":
      return user;
    case "password":
      return password;
    case "password_lock":
      return password_lock;
    case "logout":
      return logout;
    case "info":
      return info;
    case "compras":
      return compras;
    case "up":
      return up;
    default:
      return up;
  }
}
</script>

<template>
  <button class="icon-btn" :class="props.classes" @click="onTap">
    <img
      :src="getIcon(props.name)"
      :alt="props.alt"
      :width="props.width ?? 50"
    />
    <span v-if="props.text" class="py-3 px-2">{{ props.text ?? "" }}</span>
  </button>
</template>
