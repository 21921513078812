<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";

import ShoppingCartModal from "@components/shopping_cart/ShoppingCartModal.vue";
import { useShoppingCartStore } from "@composables/use_shopping_cart_store";
import { useAuthStore } from "@composables/use_auth_store";

const { token, getTokenStatus } = useAuthStore();

const props = defineProps({
  items: Array,
  showCart: Boolean,
  classes: String,
});
const emit = defineEmits(["onshowshoppingcart"]);

const { showShoppingCart, toggleShoppingCartModal, processCart } =
  useShoppingCartStore();

const menuRef = ref();
const showMenu = ref(false);

onMounted(() => {
  window.addEventListener("click", listener);
});
onBeforeUnmount(() => {
  window.removeEventListener("click", listener);
});

function listener(ev) {
  if (
    ev.target !== menuRef.value &&
    ev.composedPath().includes(menuRef.value)
  ) {
    return;
  } else {
    showMenu.value = false;
  }
}
</script>

<template>
  <div class="absolute-wraper" :class="props.classes" ref="menuRef">
    <div class="row d-none d-xl-block">
      <a
        v-for="(item, index) in props.items"
        :key="`menu_link_${index}`"
        :href="item.link"
        class="col-auto my-2 text-white px-xxl-3"
        style="font-size: 16px"
      >
        {{ item.name }}
      </a>

      <!-- Login and Register -->
      <a
        v-if="!token"
        href="/login"
        class="col-auto my-2 text-white px-xxl-3"
        style="font-size: 16px"
      >
        Iniciar Sesión
      </a>
      <a
        v-if="!token"
        href="/register"
        class="col-auto my-2 text-white px-xxl-3"
        style="font-size: 16px"
      >
        Registrarse
      </a>
    </div>
  </div>

  <ShoppingCartModal
    v-if="showShoppingCart"
    @oncloseshoppingcart="toggleShoppingCartModal"
    @onProcessCart="processCart"
  />
</template>

<style scoped>
.location {
  top: -10px !important;
  right: 0px;
}
</style>
