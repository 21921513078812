import { sendAxiosRequest } from '@api/request.js'

// obtener datos para el registro
async function getDataToFormRegister() {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/getDataToFormRegister`

  const config = {
    method: 'get',
    url: url,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
  }

  return await sendAxiosRequest(config)
}

async function register(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/register`

  const config = {
    method: 'post',
    url: url,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
    data: data,
  }

  return await sendAxiosRequest(config)
}

async function login(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/login`

  const config = {
    method: 'post',
    url: url,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
    data: data,
  }
  return await sendAxiosRequest(config)
}

async function changePassword(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/change_password`

  const config = {
    method: 'post',
    url: url,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
    data: data,
  }
  return await sendAxiosRequest(config)
}

async function sendCode(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/send_code`

  const config = {
    method: 'post',
    url: url,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
    data: data,
  }
  return await sendAxiosRequest(config)
}

async function verifyCode(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/verify_code`

  const config = {
    method: 'post',
    url: url,
    headers: {
      Accept: '*/*',
      'Access-Control-Allow-Origin': '*',
    },
    data: data,
  }
  return await sendAxiosRequest(config)
}

export {
  getDataToFormRegister,
  register,
  login,
  changePassword,
  sendCode,
  verifyCode,
}
