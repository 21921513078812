import { defineStore } from "pinia";
import { getPromotions } from "@api/promotions_api.js";

export const promotionsStore = defineStore("promotionsStore", {
  state: () => ({
    status: 200,
    loadingPromotions: false,
    message: "",
    promotions: [],
    promotionsSearchResult: [],
  }),
  actions: {
    async getPromotions() {
      this.loadingPromotions = true;

      const response = await getPromotions();
      this.status = response.status;
      this.message = response.message;

      if (response.status === 200) {
        this.promotions = response.data.data.map((item) => {
          return {
            id: item.id,
            image: item.image
              ? `${import.meta.env.VITE_IMAGES_BASE_URL}/images/${
                  item.image?.image_name
                }`
              : null,
            small_image: item.imagemobile
              ? `${import.meta.env.VITE_IMAGES_BASE_URL}/images/thumbs/${
                  item.imagemobile?.image_name
                }`
              : null,
            link: item.url ?? null,
            active: false,
          };
        });
        this.promotionsSearchResult = this.promotions.filter((item) => item);
      } else {
        console.log(response.error);
      }

      this.loadingPromotions = false;
    },

    reload() {
      this.status = 200;
      this.loadingPromotions = false;
      this.message = "";
      this.promotions = [];
      this.promotionsSearchResult = [];
    },
  },
});
