import { sendAxiosRequest } from "@api/request.js";
import { shoppingCartStore } from "@stores/shopping_cart_store.js";
import { storeToRefs } from "pinia";

async function toggleShoppingCartModal(data) {
  const myShoppingCartStore = shoppingCartStore();
  myShoppingCartStore.toggleShoppingCartModal();
}

async function processCart(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/validate-stock`;

  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const config = {
    method: "post",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  return await sendAxiosRequest(config);
}

async function nuevaVenta(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/nuevaVenta`;

  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const config = {
    method: "post",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  return await sendAxiosRequest(config);
}

async function actualizarVenta(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/actualizarVenta`;

  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const config = {
    method: "post",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  return await sendAxiosRequest(config);
}

async function cancelarVenta(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/cancelar-venta`;

  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const config = {
    method: "post",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
    data: data,
  };

  return await sendAxiosRequest(config);
}

async function cambiarMetodoPago(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/cambiarMetodoPago`;

  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const config = {
    method: "post",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
    data: data,
  };

  return await sendAxiosRequest(config);
}

async function showPayButton(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/pay-button`;

  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const config = {
    method: "post",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
      "X-Custom-Header": "Codemakers",
    },
    data: {
      email: data.email,
      cartItems: data.cartItems,
      saleId: data.saleId,
    },
  };

  localStorage.setItem("saleId", data.saleId);

  const response = await sendAxiosRequest(config);

  document.getElementById("loadingText").innerHTML = "";
  document.getElementById("loadingSpinner").classList.add("d-none");
  document.getElementById("loading").classList.remove("d-none");
  //Modal
  document.getElementById("spinnerModal").classList.add("d-none");

  const mlScript = document.createElement("script");
  let preferenceId = response.data.preferenceId;

  mlScript.dataset.preferenceId = preferenceId;
  mlScript.src =
    "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";

  document.querySelector("#payForm").append(mlScript);
  mlScript.addEventListener("load", (event) => {
    document.getElementById("spinnerModal").classList.add("d-none");
    document
      .getElementsByClassName("mercadopago-button")[0]
      .addEventListener("mousedown", (event) => {
        event.preventDefault;
        toggleShoppingCartModal();
        localStorage.setItem("basterShopingCart", JSON.stringify([]));
        localStorage.setItem("preferenceId", "");
        localStorage.setItem("saleId", "");
        localStorage.setItem("cartModified", false);
      });
  });
  return preferenceId;
}

export {
  processCart,
  nuevaVenta,
  actualizarVenta,
  showPayButton,
  cancelarVenta,
  cambiarMetodoPago,
};
