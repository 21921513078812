import { defineStore } from "pinia";
import { getBrands } from "@api/brands_api.js";

export const brandsStore = defineStore("brandsStore", {
  state: () => ({
    status: 200,
    loadingBrands: false,
    message: "",
    brands: [],
    brandsSearchResult: [],
    phones: [],
    emails: [],
  }),
  actions: {
    async getBrands() {
      this.loadingBrands = true;

      const response = await getBrands();
      this.status = response.status;
      this.message = response.message;

      if (response.status === 200) {
        this.brands = response.data.data;
        this.brandsSearchResult = this.brands.filter((item) => item);

        const whith_phone = this.brands.filter((item) => item.phone);
        this.phones = whith_phone.map((item) => {
          return { name: item.name, phone: item.phone };
        });

        const whith_email = this.brands.filter((item) => item.email);
        this.emails = whith_email.map((item) => {
          return { name: item.name, email: item.email };
        });
      } else {
        console.log(response.error);
      }

      this.loadingBrands = false;
    },

    reload() {
      this.status = 200;
      this.loadingBrands = false;
      this.message = "";
      this.brands = [];
      this.brandsSearchResult = [];
      this.phones = [];
      this.emails = [];
    },
  },
});
