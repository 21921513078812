import { createApp } from './main'

import '@assets/scss/bootstrap.min.css'
import '@assets/scss/main.scss'

const { app, router } = createApp()

// wait until router is ready before mounting to ensure hydration match
router.isReady().then(() => {
  app.mount('#app')

  console.log('hydrated')
})
