<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import Icon from "@components/icons/Icon.vue";

import ShoppingCartModal from "@components/shopping_cart/ShoppingCartModal.vue";
import { useShoppingCartStore } from "@composables/use_shopping_cart_store";
import { useAuthStore } from "@composables/use_auth_store";

const { token, getTokenStatus } = useAuthStore();

const props = defineProps({
  items: Array,
  showCart: Boolean,
  classes: String,
});
const emit = defineEmits(["onshowshoppingcart"]);

const { showShoppingCart, toggleShoppingCartModal, processCart } =
  useShoppingCartStore();

const menuRef = ref();
const showMenu = ref(false);

onMounted(() => {
  window.addEventListener("click", listener);
});
onBeforeUnmount(() => {
  window.removeEventListener("click", listener);
});

function logout() {
  localStorage.setItem("token", "");
  localStorage.setItem("cliente", "");
  localStorage.setItem("email", "");
  getTokenStatus();
  alert("Sesión cerrada correctamente");
  window.location.href = window.location.origin;
}

function listener(ev) {
  if (
    ev.target !== menuRef.value &&
    ev.composedPath().includes(menuRef.value)
  ) {
    return;
  } else {
    showMenu.value = false;
  }
}
</script>

<template>
  <div class="absolute-wraper" :class="props.classes" ref="menuRef">
    <div class="row d-none d-xl-block">
      <a
        v-for="(item, index) in props.items"
        :key="`menu_link_${index}`"
        :href="item.link"
        class="col-auto my-2 text-white px-xxl-3"
        style="font-size: 20px"
      >
        {{ item.name }}
      </a>

      <!-- Login and Register -->
      <a
        v-if="!token"
        href="/login"
        class="col-auto my-2 text-white px-xxl-3"
        style="font-size: 20px"
      >
        Iniciar Sesión
      </a>
      <a
        v-if="!token"
        href="/register"
        class="col-auto my-2 text-white px-xxl-3"
        style="font-size: 20px"
      >
        Registrarse
      </a>
    </div>

    <!-- menu collapse -->
    <Icon
      :name="token ? 'user' : 'menu'"
      :width="35"
      :classes="'menu-btn d-xl-none'"
      @ontap="showMenu = true"
      @mouseover="showMenu = true"
    />

    <transition-group v-if="showMenu" tag="div" appear>
      <div
        class="absolute-container left row border"
        :class="{ up: props.up }"
        style="background-color: #e41b1b; width: 160px"
        key="links"
        @mouseleave="showMenu = false"
      >
        <a
          v-for="(item, index) in props.items"
          :key="`menu_link_${index}`"
          :href="item.link"
          class="m-2 col-12 text-white"
        >
          {{ item.name }}
        </a>
        <!-- Login and Register -->
        <a v-if="!token" href="/login" class="m-2 col-12 text-white">
          Iniciar Sesión
        </a>
        <a v-if="!token" href="/register" class="m-2 col-12 text-white">
          Registrarse
        </a>

        <!-- mis compras, change password and logout -->
        <a v-if="token" href="/mis_compras" class="m-2 col-12 text-white"
          >Mis compras</a
        >
        <a v-if="token" href="/change_password" class="m-2 col-12 text-white"
          >Cambiar contraseña</a
        >
        <a v-if="token" href="#" @click="logout" class="m-2 col-12 text-white"
          >Cerrar Sesión</a
        >
      </div>
    </transition-group>
  </div>

  <ShoppingCartModal
    v-if="showShoppingCart"
    @oncloseshoppingcart="toggleShoppingCartModal"
    @onProcessCart="processCart"
  />
</template>

<style scoped>
.location {
  top: -10px !important;
  right: 0px;
}
</style>
