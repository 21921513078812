<script setup>
import { ref } from "vue";
import Icon from "@components/icons/Icon.vue";

const props = defineProps({
  placeholder: String,
  criteria: String,
});
const emit = defineEmits(["onsearchbarsearch", "onsearchbarchange"]);

const placeholder = ref(props.placeholder ?? "Buscar...");
const criteria = ref(props.criteria ?? "");

function onSearch() {
  console.log("on search bar search");
  emit("onsearchbarsearch", criteria.value);
}
function onChange() {
  console.log("on search bar change");
  if (criteria.value != "") {
    emit("onsearchbarchange", criteria.value);
  }
}

function onClear() {
  console.log("on search bar clear");
  criteria.value = "";
  emit("onsearchbarsearch", criteria.value);
  emit("onsearchbarchange", criteria.value);
}
</script>

<template>
  <div class="search-bar-container">
    <div class="search-bar-inner">
      <Icon :name="'search'" :width="30" @ontap="onChange" />

      <input
        v-model="criteria"
        @keyup.enter="onChange"
        type="text"
        :placeholder="placeholder ? placeholder : ''"
      />

      <Icon v-if="criteria" :name="'close'" :width="30" @ontap="onClear" />
    </div>
  </div>
</template>
