// Handle fetch erros
function handleFetchError(error) {
  let message = "";
  let error_message = "";

  switch (typeof error) {
    case ReferenceError:
      message = "ReferenceError";
      error_message = ReferenceError(error).message;
      break;

    case RangeError:
      message = "RangeError";
      error_message = RangeError(error).message;
      break;

    case MediaError:
      message = "MediaError";
      error_message = MediaError(error).message;
      break;

    case EvalError:
      message = "EvalError";
      error_message = EvalError(error).message;
      break;

    case SyntaxError:
      message = "SyntaxError";
      error_message = SyntaxError(error).message;
      break;

    case TypeError:
      message = "TypeError";
      error_message = TypeError(error).message;
      break;

    default:
      message = "Error";
      error_message = Error(error).message;
      break;
  }

  return {
    code: 0,
    message: message,
    data: null,
    error: error_message,
  };
}

// Handle axios erros
function handleAxiosError(error) {
  let code = 0;
  let message = "";
  let error_message = "";

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the status range of 2xx
    console.log(error.response.status);
    console.log(error.response.data);

    code = error.response.status;
    switch (code) {
      case 500:
        message = "Error del servidor";
        error_message = "Error del servidor";
        break;

      default:
        message = "Error desconocido";
        error_message = "Error desconocido";
        break;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    code = 500;
    message = "Error del servidor";
    error_message = "Error del servidor";
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
    code = 500;
    message = "Error en la petición";
    error_message = error.message;
  }

  // console.log(error.config);

  return {
    code: code,
    message: message,
    data: null,
    error: error_message,
  };
}
export {
  // handle fetch errors
  handleFetchError,
  // handle axios errors
  handleAxiosError,
};
