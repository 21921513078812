import { defineStore } from "pinia";
import { getMisCompras, getCompraDetails } from "@api/mis_compras_api.js";

export const misComprasStore = defineStore("misComprasStore", {
  state: () => ({
    //Definiendo estado de Mis Compras
    misComprasItemCount: 0,
    misCompras: [],
    miCompraItems: [],
    miCompra: [],
    showCompraDetails: false,
    loadingCompras: true,
  }),
  actions: {
    async getMisCompras(data) {
      this.loadingCompras = true;
      const response = await getMisCompras(data);
      this.loadingCompras = false;
      switch (response.code) {
        case 403:
          alert("Error en los datos de entrada");
          return false;
        case 401:
          alert("Autenticación requerida");
          return false;
        case 423:
          alert("Error al obtener los datos de las compras del cliente");
          return false;
        default:
          if (response.status === 200) {
            return response.data;
          } else {
            alert("Error al obtener los datos de las compras del cliente");
            return false;
          }
      }
    },

    async getCompraDetails(data) {
      const response = await getCompraDetails({
        id: data.id,
      });
      switch (response.code) {
        case 403:
          alert("Error en los datos de entrada");
          return false;
        case 401:
          alert("Autenticación requerida");
          return false;
        case 423:
          alert("Error al obtener los detalles de la compra del cliente");
          return false;
        default:
          if (response.status === 200) {
            this.miCompra = {
              id: data.miCompra.id,
              is_canceled: data.isCanceled ? 1 : 0,
              is_pending: data.isPending ? 1 : 0,
              created_at: data.miCompra.created_at,
              amount: data.miCompra.amount,
            };
            this.miCompraItems = response.data;
            return true;
          } else {
            alert("Error al obtener los detalles de la compra del cliente");
            return false;
          }
      }
    },

    async initMisCompras(misCompras) {
      this.misComprasItemCount = misCompras.length;
      this.misCompras = misCompras;
    },

    async toggleCompraDetailsModal() {
      this.showCompraDetails = !this.showCompraDetails;
    },

    showCompraDetailsModal() {
      this.showCompraDetails = false;
    },

    hideCompraDetailsModal() {
      this.showCompraDetails = false;
    },

    async cancelarVenta(data) {
      const response = await cancelarVenta(data);

      switch (response.code) {
        case 403:
          alert("Error en la entrada de datos");
          return false;
          break;
        case 401:
          alert("Autenticación requerida");
          return false;
          break;
        case 423:
          alert("Error al cancelar la venta");
          return false;
          break;
        default:
          if (response.status === 200) {
            return response.data;
          } else {
            alert("Error al cancelar la venta");
            return false;
          }
      }
    },
  },
});
