import { defineStore } from "pinia";

import {
  getDataToFormRegister,
  login,
  register,
  changePassword,
  sendCode,
  verifyCode,
} from "@api/auth_api.js";

export const authStore = defineStore("authStore", {
  state: () => ({
    provincias: [],
    localidades: [],
    formasPago: [],
    condicionesIva: [],
    cliente: "",
    email: "",
    token: "",
    back_url: "/",
  }),

  actions: {
    async getDataToFormRegister() {
      const response = await getDataToFormRegister();
      this.status = response.status;
      this.message = response.message;

      if (response.status === 200) {
        let data = response.data;
        if (data) {
          this.provincias = data.provincias;
          this.localidades = data.localidades;
          this.formasPago = data.formasPago;
          this.condicionesIva = data.condicionesIva;
        }
      } else {
        console.log(response.error);
      }
    },

    async login(data) {
      const response = await login(data);
      switch (response.code) {
        case 403:
          alert("Verifique los datos de entrada");
          return false;
        case 400:
          alert("Credenciales incorrectas");
          return false;
        case 403:
          alert("Cliente no autorizado");
          return false;
        default:
          if (response.status === 200) {
            const generateToken = response.data
              ? response.data.data["token"]
              : null;
            if (generateToken) {
              localStorage.setItem("token", generateToken);
              this.token = generateToken;
              this.cliente = response.data ? response.data.data["cliente"] : "";
              localStorage.setItem("cliente", this.cliente);
              this.email = response.data ? response.data.data["email"] : "";
              localStorage.setItem("email", this.email);
            }
            return true;
          } else {
            alert("Error al iniciar sesión");
            return false;
          }
      }
    },

    async changePassword(data) {
      const response = await changePassword(data);
      switch (response.code) {
        case 422:
          alert("Verifique los datos de entrada");
          return false;
          break;
        case 403:
          alert("La constraseña actual es incorrecta");
          return false;
          break;
        case 404:
          alert("Cliente no encontrado");
          return false;
          break;
        default:
          if (response.status === 200) {
            alert("Contraseña actualizada correctamente");
            return true;
          } else {
            alert("Error al cambiar la contraseña");
            return false;
          }
      }
    },

    async sendCode(data) {
      let lbCodigoEnviado = document.getElementById("lbCodigoEnviado");
      let sendCodeBtn = document.getElementById("sendCodeBtn");
      let loadingBtn = document.getElementById("loadingBtn");
      lbCodigoEnviado.classList.add("d-none");
      sendCodeBtn.classList.add("d-none");
      loadingBtn.classList.remove("d-none");

      const response = await sendCode(data);

      sendCodeBtn.classList.remove("d-none");
      loadingBtn.classList.add("d-none");

      switch (response.code) {
        case 403:
          alert("Verifique los datos de entrada");
          return false;
        case 422:
          alert("Error al enviar el código de activación");
          return false;
        case 404:
          alert("El correo electrónico no está registrado a ningún cliente");
          return false;
        default:
          if (response.status === 200) {
            lbCodigoEnviado.classList.remove("d-none");
            return true;
          } else {
            alert("Error. Código no enviado");
            return false;
          }
      }
    },

    async verifyCode(data) {
      let loadingVerifyCode = document.getElementById("loadingVerifyCode");
      loadingVerifyCode.classList.remove("d-none");

      const response = await verifyCode(data);

      loadingVerifyCode.classList.add("d-none");

      switch (response.code) {
        case 422:
          alert("Verifique los datos de entrada");
          return false;
        case 404:
          alert("Correo electrónico no registrado");
          return false;
        case 403:
          let divVerifyIncorrect =
            document.getElementById("divVerifyIncorrect");
          divVerifyIncorrect.classList.remove("d-none");
          return false;
        case 423:
          alert("Error al reiniciar la contraseña");
          return false;
        default:
          if (response.status === 200) {
            let divVerifyOK = document.getElementById("divVerifyOK");
            divVerifyOK.classList.remove("d-none");
            return true;
          } else {
            alert("Error. Código no enviado");
            return false;
          }
      }
    },

    async getTokenStatus() {
      if (typeof Storage !== undefined) {
        this.token = localStorage.getItem("token")
          ? localStorage.getItem("token")
          : null;
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async getCliente() {
      if (typeof Storage !== undefined) {
        this.cliente = localStorage.getItem("cliente")
          ? localStorage.getItem("cliente")
          : null;
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async getEmail() {
      if (typeof Storage !== undefined) {
        this.email = localStorage.getItem("email")
          ? localStorage.getItem("email")
          : null;
      } else {
        alert("Almacenamiento local no disponible!!!");
      }
    },

    async register(data) {
      const response = await register(data);
      switch (response.code) {
        case 422:
          alert(`Verifique los datos de entrada. Cliente no registrado`);
          break;
        case 400:
          alert("No se puede registrar usando este email");
          break;
        default:
          if (response.status === 200) {
            console.log(response.message);
            alert("Cliente registrado correctamente");
            let resp = await this.login(data);
            return true;
          } else {
            alert("Error al registrarse");
          }
      }
    },

    reload() {},
  },
});
