import { storeToRefs } from "pinia";
import { inventoryStore } from "@stores/inventory_store.js";

export const useInventoryStore = () => {
  const myInventoryStore = inventoryStore();

  const {
    status,
    loadingCategories,
    loadingSubcategories,
    loadingProducts,
    loadingProductDetail,
    searchingProducts,
    searching,
    criteria,
    categories,
    categorySelected,
    subcategories,
    subcategorySelected,
    productsOnPromo,
    productsFeatured,
    productBuffer,
    products,
    productSelected,
    productsSearchResult,
    crumbs,
    productCurrentPage,
    productLastPage,
  } = storeToRefs(myInventoryStore);

  //----------------------------------------------------------------------------- categories
  const getCategories = async () => {
    await myInventoryStore.getCategories();
  };
  const setCategory = (id) => {
    myInventoryStore.setCategory(id);
  };
  const getCrumbsCat = async (catId) => {
    categories.value.forEach((cat) => {
      if (cat.id == catId) {
        crumbs.value = [
          {
            title: cat.nombre,
            name: "Category",
            params: { category_id_slug: `${cat.id}_${cat.slug}` },
            url: "#",
          },
        ];
      }
    });
  };

  //----------------------------------------------------------------------------- subcategories
  const getSubcategories = async (catId) => {
    await myInventoryStore.getSubcategories(catId);
  };
  const setSubcategory = (id) => {
    myInventoryStore.setSubcategory(id);
  };
  const getCrumbsSub = async (catId, subcatId) => {
    subcategories.value.forEach((sub) => {
      if (sub.id == subcatId) {
        categories.value.forEach((cat) => {
          if (cat.id == catId) {
            crumbs.value = [
              {
                title: cat.nombre,
                name: "Category",
                params: { category_id_slug: `${cat.id}_${cat.slug}` },
                url: `/categorias/${cat.id}_${cat.slug}`,
              },
              {
                title: sub.nombre,
                name: "Subcategory",
                params: {
                  category_id_slug: `${cat.id}_${cat.slug}`,
                  subcategory_id_slug: `${sub.id}_${sub.slug}`,
                },
                url: "#",
              },
            ];
          }
        });
      }
    });
  };

  //----------------------------------------------------------------------------- products
  const getPromos = async () => {
    await myInventoryStore.getPromos();
  };
  const getFeatured = async () => {
    await myInventoryStore.getFeatured();
  };
  const getAllProducts = async (perPage = 50) => {
    if (!loadingProducts.value) {
      await getProductRecursive(perPage, 1);
    }
  };
  const getProductPaginated = async (perPage, page) => {
    await myInventoryStore.getProductPaginated(perPage, page);
    loadingProducts.value = false;
  };
  const selectProduct = async (id) => {
    await myInventoryStore.selectProduct(id);
  };
  const setCriteria = (criteria) => {
    myInventoryStore.setCriteria(criteria);
  };
  const getProductBySubcategory = async (categoryId, subcategoryId) => {
    await myInventoryStore.getProductBySubcategory(categoryId, subcategoryId);
  };
  const getProductByCategory = async (categoryId, page) => {
    await myInventoryStore.getProductByCategory(categoryId, page);
  };
  const getProductBySlug = async (slug) => {
    products.value.forEach((product) => {
      if (product.slug == slug) {
        productSelected.value = product;

        crumbs.value = [
          {
            title: product.category,
            name: "Category",
            params: {
              category_id_slug: `${product.category_id}_${product.category}`,
            },
            url: `/categorias/${product.category_id}_${product.category}`,
          },
          {
            title: product.subcategory,
            name: "Subcategory",
            params: {
              category_id_slug: `${product.category_id}_${product.category}`,
              subcategory_id_slug: `${product.subcategory_id}_${product.subcategory}`,
            },
            url: `/categorias/${product.category_id}_${product.category}/${product.subcategory_id}_${product.subcategory}`,
          },
          {
            title: product.name,
            name: "ProductDetails",
            params: {
              slug: product.slug,
            },
            url: "#",
          },
        ];

        loadingProductDetail.value = false;
      }
    });

    if (!loadingProductDetail.value && productSelected.value == null) {
      console.log("not found");
      await myInventoryStore.getProductBySlug(slug);

      crumbs.value = [
        {
          title: productSelected.value.category,
          name: "Category",
          params: {
            category_id_slug: `${productSelected.value.category_id}_${productSelected.value.category}`,
          },
          url: `/categorias/${productSelected.value.category_id}_${productSelected.value.category}`,
        },
        {
          title: productSelected.value.subcategory,
          name: "Subcategory",
          params: {
            category_id_slug: `${productSelected.value.category_id}_${productSelected.value.category}`,
            subcategory_id_slug: `${productSelected.value.subcategory_id}_${productSelected.value.subcategory}`,
          },
          url: `/categorias/${productSelected.value.category_id}_${productSelected.value.category}/${productSelected.value.subcategory_id}_${productSelected.value.subcategory}`,
        },
        {
          title: productSelected.value.name,
          name: "ProductDetails",
          params: {
            slug: productSelected.value.slug,
          },
          url: "#",
        },
      ];
    }
  };
  const searchProduct = async () => {
    clearSearch();
    await myInventoryStore.searchProduct();
  };
  const stopSearch = () => {
    myInventoryStore.stopSearch();
  };
  const clearSearch = () => {
    myInventoryStore.clearSearch();
  };
  const reload = () => {
    myInventoryStore.reload();
  };

  async function getProductRecursive(perPage, page) {
    await myInventoryStore.getProductPaginated(perPage, page);
    products.value = [...products.value, ...productBuffer.value];

    if (page++ <= productLastPage.value) {
      getProductRecursive(perPage, page);
    }
  }

  return {
    ///* properties
    status,
    loadingCategories,
    loadingSubcategories,
    loadingProducts,
    loadingProductDetail,
    searchingProducts,
    searching,
    criteria,
    categories,
    categorySelected,
    subcategories,
    subcategorySelected,
    productsOnPromo,
    productsFeatured,
    productBuffer,
    products,
    productSelected,
    productsSearchResult,
    crumbs,
    productCurrentPage,
    productLastPage,

    ///* methods
    getCategories,
    setCategory,
    getCrumbsCat,

    getSubcategories,
    setSubcategory,
    getCrumbsSub,

    getPromos,
    getFeatured,
    getAllProducts,
    getProductPaginated,
    selectProduct,
    setCriteria,
    getProductBySubcategory,
    getProductByCategory,
    getProductBySlug,
    searchProduct,
    stopSearch,
    clearSearch,
    reload,
  };
};
