<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import facebook from "/src/assets/img/networks/facebook.svg";
import instagram from "/src/assets/img/networks/instagram.svg";
import telegram from "/src/assets/img/networks/telegram.svg";
import tiktok from "/src/assets/img/networks/tiktok.svg";
import whatsapp from "/src/assets/img/networks/whatsapp.svg";
import youtube from "/src/assets/img/networks/youtube.svg";
import xlink from "/src/assets/img/networks/youtube.svg";

const props = defineProps({
  type: String,
  brands: Array,
  up: Boolean,
});

const componentRef = ref();
const showLinks = ref(false);

onMounted(() => {
  window.addEventListener("click", listener);
});
onBeforeUnmount(() => {
  window.removeEventListener("click", listener);
});

function listener(ev) {
  if (
    ev.target !== componentRef.value &&
    ev.composedPath().includes(componentRef.value)
  ) {
    return;
  } else {
    showLinks.value = false;
  }
}
function getIcon(type) {
  switch (type) {
    case "facebook":
      return facebook;
    case "instagram":
      return instagram;
    case "telegram":
      return telegram;
    case "tiktok":
      return tiktok;
    case "whatsapp":
      return whatsapp;
    case "youtube":
      return youtube;
    default:
      return xlink;
  }
}
function getLink(type, item) {
  switch (type) {
    case "facebook":
      return item.facebook_link;
    case "instagram":
      return item.instagram_link;
    case "telegram":
      return item.telegram_link;
    case "tiktok":
      return item.tikTok_link;
    case "whatsapp":
      return item.whatsApp_link;
    case "youtube":
      return item.youtube_link;
    default:
      return item.x_link;
  }
}
</script>

<template>
  <div class="absolute-wraper" ref="componentRef">
    <img
      class="network-btn"
      :src="getIcon(props.type)"
      :alt="props.type"
      @mouseover="showLinks = true"
      @click="showLinks = true"
    />

    <transition-group v-if="showLinks" tag="div" name="link-list" appear>
      <div
        class="absolute-container left row"
        :class="{ up: props.up }"
        key="links"
        @mouseleave="showLinks = false"
      >
        <a
          v-for="(item, index) in props.brands"
          :key="`link_${index}`"
          :href="getLink(props.type, item)"
          target="_blank"
          class="link col-12"
        >
          {{ item.name }}
        </a>
      </div>
    </transition-group>
  </div>
</template>
