import axios from "axios";
import { handleFetchResponse, handleAxiosResponse } from "@api/handle_response";
import { handleFetchError, handleAxiosError } from "@api/handle_error";

// Send fetch request
async function sendFetchRequest(url, requestOptions) {
  // fetch the request
  return await fetch(url, requestOptions)
    .then(handleFetchResponse)
    .catch(handleFetchError);
}

// Send fetch request
async function sendAxiosRequest(config) {
  // axios the request
  return await axios(config).then(handleAxiosResponse).catch(handleAxiosError);
}

export {
  // send request
  sendFetchRequest,
  sendAxiosRequest,
};
