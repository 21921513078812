<script setup>
const props = defineProps({
  width: Number,
  source: String,
  hint: String,
  classes: String,
});
const emit = defineEmits(["ontap"]);

function onTap() {
  console.log("image logo ontap");
  emit("ontap");
}
</script>

<template>
  <img
    :width="props.width ?? 180"
    :src="props.source"
    :alt="props.hint ?? 'image_logo'"
    :class="classes"
    :title="props.hint"
    @click.stop="onTap"
  />
</template>

<style scoped>
img {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  img {
    width: 80px;
  }
}

@media screen and (max-width: 320px) {
  img {
    width: 60px;
  }
}
</style>
