import { storeToRefs } from "pinia";
import { misComprasStore } from "@stores/mis_compras_store.js";
import Swal from "sweetalert2";

import { useRouter } from "vue-router";

export const useMisComprasStore = () => {
  const myMisComprasStore = misComprasStore();

  const router = useRouter();

  const {
    misComprasItemCount,
    misCompras,
    miCompraItems,
    showCompraDetails,
    loadingCompras,
  } = storeToRefs(myMisComprasStore);

  const initMisCompras = async () => {
    const email = localStorage.getItem("email");
    const cliente = localStorage.getItem("cliente");
    const misCompras = await myMisComprasStore.getMisCompras({
      email: email,
      cliente: cliente,
    });
    if (misCompras) {
      await myMisComprasStore.initMisCompras(misCompras);
    }
  };

  const getCompraDetails = async (data) => {
    let response = await myMisComprasStore.getCompraDetails(data);
    if (response) {
      toggleCompraDetailsModal();
    }
  };

  const toggleCompraDetailsModal = () => {
    myMisComprasStore.toggleCompraDetailsModal();
  };

  return {
    ///* properties
    misComprasItemCount,
    misCompras,
    showCompraDetails,
    loadingCompras,
    ///* methods
    initMisCompras,
    toggleCompraDetailsModal,
    getCompraDetails,
  };
};
