import { storeToRefs } from "pinia";
import { authStore } from "@stores/auth_store.js";
import { useRouter } from "vue-router";

export const useAuthStore = () => {
  const myAuthStore = authStore();

  const router = useRouter();

  const {
    status,
    message,
    provincias,
    localidades,
    formasPago,
    condicionesIva,
    token,
    cliente,
    email,
    password,
    back_url,
  } = storeToRefs(myAuthStore);

  const setBackUrl = (value) => {
    myAuthStore.back_url = value;
  };

  const getDataToFormRegister = async () => {
    await myAuthStore.getDataToFormRegister();
  };

  const login = async (data) => {
    let response = await myAuthStore.login(data);
    if (response) {
      alert("Ha iniciado sesión correctamente");
      window.location.href = window.location.origin + myAuthStore.back_url;
      setBackUrl("/");
    }
  };

  const changePassword = async (data) => {
    return await myAuthStore.changePassword(data);
  };

  const sendCode = async (data) => {
    return await myAuthStore.sendCode(data);
  };

  const verifyCode = async (data) => {
    return await myAuthStore.verifyCode(data);
  };

  const getTokenStatus = async () => {
    await myAuthStore.getTokenStatus();
  };

  const getCliente = async () => {
    await myAuthStore.getCliente();
  };

  const getEmail = async () => {
    await myAuthStore.getEmail();
  };

  const register = async (data) => {
    let resp = await myAuthStore.register(data);
    if (resp) {
      router.push("/");
    }
  };

  const reload = () => {
    myAuthStore.reload();
  };

  return {
    ///* properties
    status,
    message,
    provincias,
    localidades,
    formasPago,
    condicionesIva,
    token,
    cliente,
    email,
    password,
    back_url,

    ///* methods
    getDataToFormRegister,
    setBackUrl,
    login,
    changePassword,
    sendCode,
    verifyCode,
    getTokenStatus,
    getCliente,
    getEmail,
    register,
    reload,
  };
};
