import { defineStore } from "pinia";
import { getTextBanners } from "@api/textbanners_api.js";

export const textBannersStore = defineStore("textBannersStore", {
  state: () => ({}),
  actions: {
    async getTextBanners(data) {
      const response = await getTextBanners(data);

      switch (response.code) {
        default:
          if (response.status === 200) {
            return response.data;
          } else {
            alert("Error al obtener los anuncios de texto");
            return false;
          }
      }
    },
  },
});
