import { defineStore } from "pinia";
import { getMicelaneas } from "@api/micelaneas_api.js";

export const micelaneasStore = defineStore("micelaneasStore", {
  state: () => ({
    status: 200,
    loadingMicelaneas: false,
    message: "",
    quienes_somos: "",
    preguntas_frecuentes: "",
    terminos_condiciones: "",
    sucursales: "",
    envios: "",
  }),
  actions: {
    async getMicelaneas() {
      this.loadingMicelaneas = true;

      const response = await getMicelaneas();
      this.status = response.status;
      this.message = response.message;

      if (response.status === 200) {
        let micelaneas = response.data.data;
        if (micelaneas.length) {
          this.quienes_somos = micelaneas[0].quienes_somos;
          this.preguntas_frecuentes = micelaneas[0].preguntas_frecuentes;
          this.terminos_condiciones = micelaneas[0].terminos_condiciones;
          this.sucursales = micelaneas[0].sucursales;
          this.envios = micelaneas[0].envios;
        }
      } else {
        console.log(response.error);
      }

      this.loadingMicelaneas = false;
    },

    reload() {
      this.status = 200;
      this.loadingMicelaneas = false;
      this.message = "";
      this.quienes_somos = "";
      this.preguntas_frecuentes = "";
      this.terminos_condiciones = "";
      this.sucursales = "";
      this.envios = "";
    },
  },
});
