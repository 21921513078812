import {
  createRouter as _createRouter,
  createMemoryHistory,
  createWebHistory,
} from "vue-router";
import GuestLayout from "@components/layouts/GuestLayout.vue";

let routes = [
  // ----------------------------------------------------------------- Pages
  {
    path: "/",
    name: "Home",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/Home.vue"),
  },
  {
    path: "/categorias/:category_id_slug",
    name: "Category",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/Category.vue"),
  },
  {
    path: "/categorias/:category_id_slug/:subcategory_id_slug",
    name: "Subcategory",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/Subcategory.vue"),
  },
  {
    path: "/productos/:slug",
    name: "ProductDetails",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/ProductDetails.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/Register.vue"),
  },
  {
    path: "/change_password",
    name: "ChangePassword",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/ChangePassword.vue"),
  },
  {
    path: "/reset_password",
    name: "ResetPassword",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/ResetPassword.vue"),
  },
  {
    path: "/quienes_somos",
    name: "AboutUs",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/AboutUs.vue"),
  },
  {
    path: "/carrito/mp/pago-exitoso",
    name: "pago-exitoso",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/carrito/mp/pago-exitoso.vue"),
  },
  {
    path: "/carrito/mp/pago-pendiente",
    name: "pago-pendiente",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/carrito/mp/pago-pendiente.vue"),
  },
  {
    path: "/carrito/mp/pago-fallido",
    name: "pago-fallido",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/carrito/mp/pago-fallido.vue"),
  },
  {
    path: "/preguntas_frecuentes",
    name: "FrequentQuestions",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/FrequentQuestions.vue"),
  },
  {
    path: "/shopping_cart",
    name: "ShoppingCart",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/ShoppingCart.vue"),
  },
  {
    path: "/mis_compras",
    name: "MisCompras",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/MisCompras.vue"),
  },
  {
    path: "/terminos_y_condiciones",
    name: "TermsAndConditions",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/TermsAndConditions.vue"),
  },
  {
    path: "/envios",
    name: "Shipments",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/Shipments.vue"),
  },
  {
    path: "/sucursales",
    name: "Branches",
    meta: {
      layout: GuestLayout,
      requiresAuth: false,
      security: false,
    },
    component: () => import("@views/Branches.vue"),
  },

  // ----------------------------------------------------------------- Anyone else
  {
    // will match everything
    path: "/:pathMatch(.*)*",
    component: () => import("@views/404.vue"),
  },
];

export function createRouter() {
  return _createRouter({
    // use appropriate history implementation for server/client
    // import.meta.env.SSR is injected by Vite.
    history: import.meta.env.SSR
      ? createMemoryHistory("/")
      : createWebHistory("/"),
    routes,
  });
}
