<script setup>
import { onBeforeUnmount, onMounted } from "vue";
import GuestHeader from "@components/layouts/headers/GuestHeader.vue";
import GuestFooter from "@components/layouts/footers/GuestFooter.vue";
import { useShoppingCartStore } from "@composables/use_shopping_cart_store";
import { useMicelaneasStore } from "@composables/use_micelaneas_store";
import { useBrandsStore } from "@composables/use_brands_store";
import { usePromotionsStore } from "@composables/use_promotions_store";
import { useInventoryStore } from "@composables/use_inventory_store";
import { useAuthStore } from "@composables/use_auth_store";

import { useMisComprasStore } from "@composables/use_mis_compras_store";
import { misComprasStore } from "@stores/mis_compras_store.js";

const { getTokenStatus, getCliente, getEmail, token } = useAuthStore();
const { getMicelaneas } = useMicelaneasStore();
const { getBrands } = useBrandsStore();
const { getPromotions } = usePromotionsStore();
const { getCategories, getPromos, getFeatured } = useInventoryStore();
const { initShoppingCart, expiredCheck } = useShoppingCartStore();

const { initMisCompras } = useMisComprasStore();

onMounted(() => {
  console.log("guest layout mounted");

  getTokenStatus();
  getCliente();
  getEmail();
  expiredCheck();
  initShoppingCart();
  getMicelaneas();
  getBrands();
  getPromotions();
  getCategories();
  getPromos();
  getFeatured();
});

onBeforeUnmount(() => {});
</script>

<template>
  <!-- GuestLayout -->
  <div>
    <GuestHeader />
    <div class="py-4 py-sm-2 my-sm-2 my-md-5 my-lg-0 py-lg-1 py-xl-0"></div>
    <div class="py-3 py-xxl-5"></div>
    <router-view />

    <GuestFooter />
  </div>
</template>
