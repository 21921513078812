<script setup>
import Icon from "@components/icons/Icon.vue";
import { useAuthStore } from "@composables/use_auth_store";
import { useRouter } from "vue-router";

const { token, getTokenStatus, cliente } = useAuthStore();

const router = useRouter();

//onMounted(() => {});

function logout() {
  localStorage.setItem("token", "");
  localStorage.setItem("cliente", "");
  localStorage.setItem("email", "");
  getTokenStatus();
  alert("Sesión cerrada correctamente");
  window.location.href = window.location.origin;
}
</script>

<template>
  <div class="absolute-wraper">
    <div class="dropdown">
      <button class="dropbtn" style="background-color: #e41b1b">
        <Icon :name="'user'" :width="35" class="pl-3 pr-2" />
        <label
          class="userLabel d-none d-lg-block d-xl-inline d-sm-none text-white"
          style="font-size: 16px"
          >Mi cuenta
        </label>
        <Icon :name="'down'" :width="35" class="pl-3 pr-2" />
      </button>
      <div
        class="dropdown-content border"
        style="background-color: #e41b1b; border-radius: 7px"
      >
        <a
          v-if="token"
          href="/mis_compras"
          class="text-white px-2 px-lg-3 px-md-2 px-sm-2"
          style="font-size: 12px"
          ><Icon
            :name="'compras'"
            :width="25"
            class="py-2 pl-3 pr-2 d-none d-xxl-inline d-xl-inline d-lg-none d-md-none d-sm-none"
          />Mis compras</a
        >
        <a
          v-if="token"
          href="/change_password"
          class="text-white px-2 px-lg-3 px-md-2 px-sm-2"
          style="font-size: 12px"
          ><Icon
            :name="'password'"
            :width="27"
            class="py-2 pl-3 pr-2 d-none d-xxl-inline d-xl-inline d-lg-none d-md-none d-sm-none"
          />Cambiar contraseña</a
        >
        <a
          v-if="token"
          href="#"
          @click="logout"
          class="text-white px-2 py-2 pt-md-1 pt-sm-1 px-lg-3 px-md-2 px-sm-2 px-sm-1"
          style="font-size: 12px"
          ><Icon
            :name="'logout'"
            :width="25"
            class="pl-3 pr-2 d-none d-xxl-inline d-xl-inline d-lg-none d-md-none d-sm-none"
          />Cerrar Sesión</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Dropdown Button */
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 200px;
}
.userLabel {
  color: rgb(60, 60, 163);
  font-size: 12px;
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: rgb(209, 167, 167);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
