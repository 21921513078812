import { storeToRefs } from "pinia";
import { textBannersStore } from "@stores/textbanners_store.js";

export const useTextBannersStore = () => {
  const myTextBannersStore = textBannersStore();

  const {} = storeToRefs(myTextBannersStore);

  const getTextBanners = async (data) => {
    return await myTextBannersStore.getTextBanners(data);
  };

  return {
    ///* properties
    ///* methods
    getTextBanners,
  };
};
