// Handle fetch responses
async function handleFetchResponse(response) {
  if (response.ok) {
    return await response.json();
  } else {
    throw Error(response.text());
  }
}

// Handle axios responses
async function handleAxiosResponse(response) {
  switch (response.status) {
    case 200:
      return await response;

    case 201:
      return await response;

    default:
      throw Error(response.statusText);
  }
}

export {
  // handle fetch responses
  handleFetchResponse,
  // handle axios responses
  handleAxiosResponse,
};
