import { storeToRefs } from "pinia";
import { shoppingCartStore } from "@stores/shopping_cart_store.js";
import Swal from "sweetalert2";

import { useRouter } from "vue-router";

export const useShoppingCartStore = () => {
  const myShoppingCartStore = shoppingCartStore();

  const router = useRouter();

  const {
    loadingShoppingCart,
    showShoppingCart,
    shoppingCartItems,
    shoppingCartItemCount,
    shoppingCartTotal,
    idVenta,
    tipoPago,
    preferenceId,
    showCancelarVenta,
  } = storeToRefs(myShoppingCartStore);

  const initShoppingCart = async () => {
    await myShoppingCartStore.initShoppingCart();
  };

  const expiredCheck = async () => {
    await myShoppingCartStore.expiredCheck();
  };

  const toggleShoppingCartModal = () => {
    myShoppingCartStore.toggleShoppingCartModal();
  };

  const showShoppingCartModal = () => {
    myShoppingCartStore.showShoppingCartModal();
  };

  const hideShoppingCartModal = () => {
    myShoppingCartStore.hideShoppingCartModal();
  };

  const resetShoppingCart = async () => {
    await myShoppingCartStore.resetShoppingCart();
  };

  const addToShoppingCart = async (product) => {
    await myShoppingCartStore.addToShoppingCart(product);
    await myShoppingCartStore.updateData();
  };

  const removeFromShoppingCart = async (product) => {
    await myShoppingCartStore.removeFromShoppingCart(product);
    await myShoppingCartStore.updateData();

    if (shoppingCartItems.value.length == 0) {
      myShoppingCartStore.hideShoppingCartModal();
    }
  };

  const updateShoppingCart = async (product, qtty, ppId, Presentacion) => {
    await myShoppingCartStore.updateShoppingCart(
      product,
      qtty,
      ppId,
      Presentacion
    );
    await myShoppingCartStore.updateData();
  };

  const processCart = async (data) => {
    var preferenceId_value = localStorage.getItem("preferenceId") ?? "";
    if (preferenceId_value == "") {
      let response = await chequearStock(data);
      if (response) {
        modalPagos(data);
      }
    } else {
      let cartModified = localStorage.getItem("cartModified");
      let idVenta = localStorage.getItem("saleId");
      if (cartModified == "true") {
        alert(
          "El Carrito de compras ha sido modificado, procediendo a actualizar la venta"
        );
        let response = await chequearStock(data);
        if (response) {
          modalPagos(data, idVenta);
        }
        localStorage.setItem("cartModified", false);
      } else {
        modalPagos(data);
      }
    }
  };

  const chequearStock = async (data) => {
    document.getElementById("btnMP").classList.add("disabled");
    document.getElementById("btnOtrosPagos").classList.add("disabled");

    let itemVerified = await myShoppingCartStore.processCart(data);

    if (itemVerified) {
      let valid = true;
      itemVerified.forEach((item) => {
        if (item.isEnough == false) {
          valid = false;
        }
      });
      if (valid) {
        document.getElementById("loadingText").innerHTML =
          "Chequeo de disponibilidad de productos realizada. Procediendo a realizar la venta";

        return true;
      } else {
        alert(
          "No hay suficientes productos en el stock para satisfacer la demanda solicitada"
        );
        restablecerLoadingBotones();
        await myShoppingCartStore.mostrarDisponibilidad(itemVerified);
        return false;
      }
    } else {
      restablecerLoadingBotones();
    }
  };

  const modalPagos = async (data, idVenta = "") => {
    var tipoPago = data.tipoPago;
    if (tipoPago == "OtrosMedios") {
      Swal.fire({
        title: "Método de Pago",
        text: "Al pagar por otros medios, te estaremos contactando para acordar el pago, antes de entregarte la compra.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dd2736",
        cancelButtonColor: "#000000",
        confirmButtonText: "Pagar (Otros Medios)",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          var preferenceId = localStorage.getItem("preferenceId") ?? "";
          var idVenta = localStorage.getItem("saleId") ?? "";

          if (idVenta != "") {
            data.cliente = getCliente();
            data.email = getEmail();
            data.ventaId = idVenta;
            actualizarVenta(data);
            restablecerLoadingBotones();
          }

          if (preferenceId != "") {
            //Cambiar metodo de pago
            if (
              confirm(
                "Tiene una venta por pagar con Mercado Pago, ¿está seguro(a) que desea cambiar el método de Pago?"
              )
            ) {
              var idVenta = localStorage.getItem("saleId") ?? "";

              data = {
                email: getEmail(),
                ventaId: idVenta,
                tipoPago: "OtrosMedios",
              };

              cambiarMetodoPago(data);
            } else {
              restablecerLoadingBotones();
              return false;
            }
          } else {
            //Nueva venta
            data.cliente = getCliente();
            data.email = getEmail();

            nuevaVenta(data);
            Swal.fire({
              text: "Venta realizada exitosamente",
              confirmButtonColor: "#000000",
              icon: "success",
            });
            localStorage.setItem("basterShopingCart", JSON.stringify([]));
          }
          myShoppingCartStore.toggleShoppingCartModal();
          router.push("/");
        } else {
          restablecerLoadingBotones();
        }
      });
    } else {
      // MERCADO PAGO
      let htmlMessage =
        '<div id="divPagoMPModal" style="color: red; background-color: cornsilk"' +
        'class="text-center text-danger rounded p-1 mb-2">' +
        "<small>Al pagar por Mercado Pago, tu pedido estará disponible " +
        "en Sucursal, tan pronto efectúes el pago.</small>" +
        '<div id="spinnerModal"><div class="spinner-border mt-1 ml-2" role="status">' +
        '<span class="sr-only"></span></div><span class="small px-3"><em>Cargando enlace para el Pago</em></span></div>' +
        '<div class="py-2" id="mercadoPagoSpan">' +
        '<form method="POST" id="payForm"></form>' +
        "</div>" +
        "</div>";

      Swal.fire({
        title: "Método de Pago",
        html: htmlMessage,
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonColor: "#000000",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isDismissed) {
          var preferenceId = localStorage.getItem("preferenceId") ?? "";

          if (preferenceId != "") {
            let btnCancelarVenta = document.getElementById("btnCancelarVenta");
            if (!btnCancelarVenta) {
              let btnCancelarVentaDimissed = document.getElementById(
                "btnCancelarVentaDimissed"
              );
              if (btnCancelarVentaDimissed) {
                btnCancelarVentaDimissed.classList.remove("d-none");
              }
            }
          }
          return false;
        }
      });

      var preferenceId_value = localStorage.getItem("preferenceId") ?? "";

      if (idVenta != "") {
        data.cliente = getCliente();
        data.email = getEmail();
        data.ventaId = idVenta;
        let response = await actualizarVenta(data);

        if (response) {
          idVenta = response;
          tipoPago = tipoPago;

          preferenceId_value = await myShoppingCartStore.MPButtonPay(
            data,
            response
          );
          localStorage.setItem("preferenceId", preferenceId_value);
          restablecerLoadingBotones();
          return true;
        } else {
          restablecerLoadingBotones();
          return false;
        }
      } else {
        if (preferenceId_value != "") {
          // Ya se realizó la venta y se generó link de pago, cargarlo entonces...
          await loadPayButtonGenerated(preferenceId_value);
          restablecerLoadingBotones();
        } else {
          // No se ha realizado la venta. Se hace la venta y se genera link de pago...
          data.cliente = getCliente();
          data.email = getEmail();
          let response = await nuevaVenta(data);
          if (response) {
            idVenta = response;
            tipoPago = tipoPago;

            preferenceId_value = await myShoppingCartStore.MPButtonPay(
              data,
              response
            );
            localStorage.setItem("preferenceId", preferenceId_value);
            restablecerLoadingBotones();
          } else {
            restablecerLoadingBotones();
            return false;
          }
        }
      }
    }
  };

  const loadPayButtonGenerated = async (preferenceId) => {
    const mlScript = document.createElement("script");
    mlScript.dataset.preferenceId = preferenceId;
    mlScript.src =
      "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";
    document.querySelector("#payForm").append(mlScript);
    mlScript.addEventListener("load", (event) => {
      document.getElementById("spinnerModal").classList.add("d-none");
      document
        .getElementsByClassName("mercadopago-button")[0]
        .addEventListener("mousedown", (event) => {
          event.preventDefault;
          myShoppingCartStore.toggleShoppingCartModal();
          localStorage.setItem("basterShopingCart", JSON.stringify([]));
          localStorage.setItem("saleId", "");
          localStorage.setItem("preferenceId", "");
          localStorage.setItem("cartModified", false);
        });
    });
  };

  const nuevaVenta = async (data) => {
    return await myShoppingCartStore.nuevaVenta(data);
  };

  const actualizarVenta = async (data) => {
    return await myShoppingCartStore.actualizarVenta(data);
  };

  const getCliente = () => {
    if (typeof Storage !== undefined) {
      return localStorage.getItem("cliente")
        ? localStorage.getItem("cliente")
        : null;
    } else {
      return alert("Almacenamiento local no disponible!!!");
    }
  };

  const getEmail = () => {
    if (typeof Storage !== undefined) {
      return localStorage.getItem("email")
        ? localStorage.getItem("email")
        : null;
    } else {
      return alert("Almacenamiento local no disponible!!!");
    }
  };

  const restablecerLoadingBotones = () => {
    document.getElementById("loading").classList.add("d-none");
    document.getElementById("loadingText").innerHTML = "";
    document.getElementById("btnMP").classList.remove("disabled");
    document.getElementById("btnOtrosPagos").classList.remove("disabled");
  };

  const cancelarVenta = async (data) => {
    return await myShoppingCartStore.cancelarVenta(data);
  };

  const cambiarMetodoPago = async (data) => {
    let response = await myShoppingCartStore.cambiarMetodoPago(data);
    if (response) {
      alert("Compra " + response.id + " pagada con Otros Medios de Pago");
      tipoPago = "OtrosMedios";
    }
  };

  return {
    ///* properties
    loadingShoppingCart,
    showShoppingCart,
    shoppingCartItems,
    shoppingCartItemCount,
    shoppingCartTotal,
    idVenta,
    tipoPago,
    preferenceId,
    showCancelarVenta,

    ///* methods
    initShoppingCart,
    expiredCheck,
    toggleShoppingCartModal,
    showShoppingCartModal,
    hideShoppingCartModal,
    resetShoppingCart,
    addToShoppingCart,
    removeFromShoppingCart,
    updateShoppingCart,
    processCart,
    nuevaVenta,
    cancelarVenta,
    cambiarMetodoPago,
  };
};
