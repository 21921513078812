import { storeToRefs } from "pinia";
import { micelaneasStore } from "@stores/micelaneas_store.js";

export const useMicelaneasStore = () => {
  const myMicelaneasStore = micelaneasStore();

  const {
    status,
    loadingMicelaneas,
    message,
    quienes_somos,
    preguntas_frecuentes,
    terminos_condiciones,
    sucursales,
    envios,
  } = storeToRefs(myMicelaneasStore);

  const getMicelaneas = async () => {
    await myMicelaneasStore.getMicelaneas();
  };

  const reload = () => {
    myMicelaneasStore.reload();
  };

  return {
    ///* properties
    status,
    loadingMicelaneas,
    message,
    quienes_somos,
    preguntas_frecuentes,
    terminos_condiciones,
    sucursales,
    envios,

    ///* methods
    getMicelaneas,
    reload,
  };
};
