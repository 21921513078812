import { sendAxiosRequest } from "@api/request.js";
import { shoppingCartStore } from "@stores/shopping_cart_store.js";
import { storeToRefs } from "pinia";

async function getMisCompras(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/getMisCompras`;
  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const config = {
    method: "post",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  return await sendAxiosRequest(config);
}

async function getCompraDetails(data) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/getCompraDetails`;
  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const config = {
    method: "post",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + token,
    },
    data: data,
  };
  return await sendAxiosRequest(config);
}

export { getMisCompras, getCompraDetails };
