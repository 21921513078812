import { storeToRefs } from "pinia";
import { promotionsStore } from "@stores/promotions_store.js";

export const usePromotionsStore = () => {
  const myPromotionsStore = promotionsStore();

  const { status, loadingPromotions, message, promotions, promotionsSearchResult } =
    storeToRefs(myPromotionsStore);

  const getPromotions = async () => {
    await myPromotionsStore.getPromotions();
  };

  const reload = () => {
    myPromotionsStore.reload();
  };

  const filter = async (criteria) => {
    if (criteria) {
      var needle = criteria.toLowerCase();
      promotionsSearchResult.value = promotions.value.filter(
        (item) =>
          item.NAME.toLowerCase().includes(needle) ||
          item.DESCRIPTION.toLowerCase().includes(needle)
      );
    } else {
      promotionsSearchResult.value = promotions.value.filter((item) => item);
    }
  };

  return {
    ///* properties
    status,
    loadingPromotions,
    message,
    promotions,
    promotionsSearchResult,

    ///* methods
    getPromotions,
    reload,
    filter,
  };
};
