import { sendAxiosRequest } from "@api/request.js";

//----------------------------------------------------------------------------- categories
async function getCategories() {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/categorias`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

//----------------------------------------------------------------------------- subcategories
async function getSubcategories(catId) {
  const url = `${
    import.meta.env.VITE_API_BASE_URL
  }/api/categorias/${catId}/sub-categorias`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

//----------------------------------------------------------------------------- products
async function getPromos() {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/productos_promo`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

async function getFeatured() {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/productos_feat`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

async function getAllProducts() {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/productos`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

async function getProductsPaginated(perPage, page) {
  const url = `${import.meta.env.VITE_API_BASE_URL}/api/productos/${
    perPage ?? "25"
  }/paginated`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      page: page,
    },
  };

  return await sendAxiosRequest(config);
}

async function getProductBySlug(slug) {
  const url = `${
    import.meta.env.VITE_API_BASE_URL
  }/api/productos_by_slug/${slug}`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

async function searchProducts(criteria) {
  const url = `${
    import.meta.env.VITE_API_BASE_URL
  }/api/productos/search/${criteria}`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

async function getProductBySubcat(cat, sub) {
  const url = `${
    import.meta.env.VITE_API_BASE_URL
  }/api/productos_by_cat/${cat}/${sub}`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

async function getProductByCat(cat, page) {
  const url = `${
    import.meta.env.VITE_API_BASE_URL
  }/api/all_productos_cat/${cat}?page=${page}`;

  const config = {
    method: "get",
    url: url,
    headers: {
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return await sendAxiosRequest(config);
}

export {
  // categories
  getCategories,
  // subcategories
  getSubcategories,
  // products
  getPromos,
  getFeatured,
  getAllProducts,
  getProductsPaginated,
  getProductBySlug,
  searchProducts,
  getProductBySubcat,
  getProductByCat,
};
